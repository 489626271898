<template>
  <div class="infoPage"
       v-infinite-scroll="loadMore"
       :infinite-scroll-disabled="busy"
       :infinite-scroll-distance="50"
       :infinite-scroll-immediate="false"
  >
    <div class="selectBox">
      <div class="selectBtn">
        <div :class="{'btn':true,'active':selectActive == '34'}" @click="selectClick('34')">{{ tType=='CH'?'最新公告':'Announcement' }}</div>
        <div :class="{'btn':true,'active':selectActive == '35'}" @click="selectClick('35')">{{ tType=='CH'?'游览资讯':'Infomation' }}</div>
      </div>
    </div>

    <div class="right">
      <div class="r_item" v-for="item of datalist" :key="item.dynamicinfomationId" @click="go(item)">
        <div class="img scale">
          <el-image
              style="width: 100%; height: 100%"
              :src="item.dynamicinfomationCover"
              fit="cover"></el-image>
          <!--            <img :src="item.dynamicinfomationCover" alt="">-->
          <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
        </div>
        <div class="content">
          <div class="text_left">
            <div class="text1">
              <div class="tTitle">{{item.webTitle}}</div>
              <div class="ziti" v-if="tType == 'CH'">{{item.dynamicinfomationCreateTime}}</div>
              <div class="ziti" v-if="tType == 'EN'">{{item.dynamicinfomationCreateTime}}</div>
            </div>
            <div class="text2"><span v-for="(items,indexs) in item.tags">{{items}}</span></div>
            <!--              <div class="text3"><span>#{{item.type}}</span>&nbsp;{{item.webDesc}}</div>-->
            <div class="text3">&nbsp;{{item.webDesc}}</div>
          </div>
          <!--            <div class="text_right">-->
          <!--              <div class="text1">{{item.time}}</div>-->
          <!--            </div>-->
        </div>
      </div>
    </div>
    <!--      <div class="paging-device">-->
    <!--        <el-pagination-->
    <!--            layout="prev, pager, next"-->
    <!--            @size-change="handleSizeChange"-->
    <!--            @current-change="handleCurrentChange"-->
    <!--            :current-page="current_page"-->
    <!--            :page-size="per_page"-->
    <!--            :total="totalCount">-->
    <!--        </el-pagination>-->
    <!--      </div>-->
  </div>
</template>

<script>
import {dynamicinfomationList,imgURL,gettowlistinfo} from "@/api";

export default {
  name: "gonggao",
  data(){
    return {
      imgURL,
      url:'',
      totalCount: 0,
      current_page: 1,
      per_page: 10,
      datalist:[
        // {
        //   imagespath:'https://zhongtai-media-test-1309102353.cos.ap-shanghai.myqcloud.com/huyunhe/1653655737000.png',
        //   title:'遏制福寿螺蔓延 保护古运河生态',
        //   time:'2022/05/25',
        //   info:'近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺近日，在无锡市多处湿地、公园发现了福寿螺',
        //   type:'#清名桥历史文化街区'
        // }
      ],
      selectActive: '34',
      tType: 'CH',
      busy: false,
      timer:null
    }
  },
  mounted() {
    this.createlist()
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  methods:{
    loadMore: function() {
      clearTimeout(this.timer)
      this.busy = true
      if(this.totalCount > this.datalist.length){
        this.timer = setTimeout(()=>{
          this.current_page++
          this.createlist()
        },1000)
      }else{
        return
      }

    },
    selectClick(num){
      this.selectActive = num
      this.current_page = 1
      if(num == '34'){
        this.createlist()
      }
      if(num == '35'){
        this.createlist()
      }
    },
    createlist(){
      if(this.current_page == 1){
        this.datalist = []
      }
      dynamicinfomationList({
        pageNum:this.current_page,
        pageSize:this.per_page,
        dynamicinfomationType: this.selectActive
      })
          .then(res=>{
            console.log(res)
            if(res.code==200){
              res.rows.forEach((item,index)=>{
                if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
              })
              this.datalist.push(...res.rows)
              this.busy = false
              this.totalCount=res.total
            }
          })
    },
    go(item){
      if(this.selectActive == '34'){
        this.$router.push({path:'informationInfo',query:{id:item.dynamicinfomationId,type:'34'}})
      }
      if(this.selectActive == '35'){
        this.$router.push({path:'informationInfo',query:{id:item.dynamicinfomationId,type:'35'}})
      }
    },
    // 每页条数
    handleSizeChange(val) {
      this.per_page=val
      this.createlist()
    },
    // 页数
    handleCurrentChange(val) {
      this.current_page=val
      this.createlist()
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.selectBox{
  width: 1200px;
  margin: 50px auto 0;
}
.selectBtn{
  //padding: 5px;
  //border-radius: 35px;
  //border: 1px solid #D3D3D3;
  display: flex;
  width: fit-content;
  .btn{
    padding: 16px 5px;
    font-size: 20px;
    font-family: STSongti-SC-Black, STSongti-SC;
    font-weight: 900;
    color: #333333;
    //border-radius: 30px;
    cursor: pointer;
    margin-left: 30px;
    &:nth-child(1){
      margin-left: 0px;
    }
  }
  .active{
    //color: #FFFFFF;
    color: #007B8A;
    //background: #007B8A;
    border-bottom: 5px solid #007B8A;
  }
}

.ziti{
  font-size: 14px;
  font-family: STHeitiSC-Medium, STHeitiSC;
  font-weight: 600;
  color: #333333;
}
.infoPage{
  width: 100%;
  overflow: hidden;
  //height: calc(100vh - 130px);
  //background: skyblue;
  //padding: 0 0 0 55px;
  .right{
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 120px;
    margin-top: 50px;

    //height: 800px;
    //overflow: scroll;
    ////background: skyblue;
    //&::-webkit-scrollbar {
    //  width: 0px;
    //}
    .r_item{
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 30px;
      &:hover{
        cursor:pointer;
        .content .text_left .text1 .tTitle{
          color: #337D8D;
        }
        //.img img{
        //transform: scale(1.1);
        //}
      }
      &:nth-last-child(1){
        margin-bottom: 0;
      }
      .img{
        width: 380px;
        height: 200px;
        margin-right: 40px;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          //transition:all 500ms ease;
          //transform: scale(1);
        }
        .eye{
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 8px 12px;
          background: #337D8D55;
          border-radius: 5px;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .content{
        width: 1100px;
        height: 160px;
        display: flex;
        justify-content: space-between;
        .text_left{
          width: 830px;
          .text1{
            font-size: 24px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
            display: flex;
            justify-content: space-between;
            .tTitle{
              max-width: calc(100% - 150px);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1; //行数
               overflow: hidden;
            }
          }
          .text2{
            margin: 14px 0 12px;
            span{
              font-size: 12px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #333333;
              padding: 3px 13px 4px 12px;
              margin-left: 10px;
              background: rgba(120, 120, 120, 0.1);
              border-radius: 17px;
              &:nth-child(1){
                margin-left: 0;
              }
            }
          }
          .text3{
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 400;
            color: #999999;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; /*截取第三行*/
            overflow: hidden;
            span{
              font-size: 14px;
              font-family: STSongti-SC-Black, STSongti-SC;
              font-weight: 400;
              color: #007B8A;
            }
          }
        }
        .text_right{
          width: 180px;
          cursor: pointer;
          text-align: right;
          padding-right: 40px;
          box-sizing: border-box;
          .text1{
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
            margin: 0 0 45px 0;
            span{
              margin-right: 20px;
              color: #999999;
            }
          }
          .text2{
            font-size: 14px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 400;
            color: #337D8D;
            line-height: 22px;
            text-align: right;
            display: flex;
            justify-content: end;
            align-items: center;
            img{
              width: 30px;
              height: 30px;
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
  /deep/.paging-device{
    margin: 70px 0 70px -50px;
    display: flex;
    justify-content: center;
    .el-pager li{
      height: 35px;
      line-height: 35px;
    }
    .btn-prev{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-right: 40px;
      i{
        font-size: 16px;
      }
    }
    .btn-next{
      border-radius: 50%;
      box-shadow: 0px 3px 15px 2px rgba(235, 235, 235, 0.5);
      padding: 0;
      display: initial;
      width: 35px;
      height: 35px;
      margin-left: 40px;
      i{
        font-size: 16px;
      }
    }
  }
}
</style>
